.Cell {
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  padding: 0.5rem;
  background-color: #fff;
  word-break: break-all;
}

/*
Masonry has overflow on inner container hardcoded to hidden. This causes box-shadow for highlighted items to be clipped.
Target the hardcoded bem classes with important styling to override this behaviour 
Current ref link: https://github.com/bvaughn/react-virtualized/blob/master/source/Masonry/Masonry.js#L277-L295
*/
.ReactVirtualized__Masonry {
  overflow-x: visible !important;
  overflow-y: visible !important;
}

.ReactVirtualized__Masonry__innerScrollContainer {
  overflow: visible !important;
}
