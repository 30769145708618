@font-face {
  font-family: 'UniversalSans';
  src: url(./fonts/UniversalSans/UniversalSans-v1-2-0-40-30-223122111122-11-300.ttf);
  font-weight: 300;
}

@font-face {
  font-family: 'UniversalSans';
  src: url(./fonts/UniversalSans/UniversalSans-v1-2-0-40-30-223122111122-11-400.ttf);
  font-weight: 400;
}
@font-face {
  font-family: 'UniversalSans';
  src: url(./fonts/UniversalSans/UniversalSans-v1-2-0-40-30-223122111122-11-500.ttf);
  font-weight: 500;
}
@font-face {
  font-family: 'UniversalSans';
  src: url(./fonts/UniversalSans/UniversalSans-v1-2-0-40-30-223122111122-11-600.ttf);
  font-weight: 600;
}
@font-face {
  font-family: 'UniversalSans';
  src: url(./fonts/UniversalSans/UniversalSans-v1-2-0-40-30-223122111122-11-700.ttf);
  font-weight: 700;
}
@font-face {
  font-family: 'UniversalSans';
  src: url(./fonts/UniversalSans/UniversalSans-Italic-v1-2-0-40-30-223122111122-11-300.ttf);
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'UniversalSans';
  src: url(./fonts/UniversalSans/UniversalSans-Italic-v1-2-0-40-30-223122111122-11-400.ttf);
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'UniversalSans';
  src: url(./fonts/UniversalSans/UniversalSans-Italic-v1-2-0-40-30-223122111122-11-500.ttf);
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'UniversalSans';
  src: url(./fonts/UniversalSans/UniversalSans-Italic-v1-2-0-40-30-223122111122-11-600.ttf);
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: 'UniversalSans';
  src: url(./fonts/UniversalSans/UniversalSans-Italic-v1-2-0-40-30-223122111122-11-700.ttf);
  font-weight: 700;
  font-style: italic;
}

body {
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5;
  background-color: rgb(14, 14, 14);
  font-family: 'UniversalSans', sans-serif;
}

a {
  color: #5930e5;
  text-decoration: none;
  background-color: initial;
  outline: none;
  cursor: pointer;
}

p {
  margin: 0;
}

fieldset {
  min-width: 0;
  margin: 0;
  padding: 0;
  border: 0;
}

button,
input,
optgroup,
select,
textarea {
  font-size: inherit;
}

*,
:after,
:before {
  box-sizing: border-box;
}

*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-thumb {
  width: 8px;
  background: rgb(60, 60, 60);
  border-radius: 4px;
}

.Resizer {
  background: #ccc;
  opacity: 0.2;
  z-index: 3;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}

/* Styles for multi select area */
.selection-area {
  background: #7575756c;
  border: 2px solid #727272;
  border-radius: 8px;
  z-index: 1000;
  user-select: none;
}

.selection-container {
  z-index: 1000 !important;
  user-select: none;
}

/* hack that fixes un-removed hanging tippy-poppers if react-tippy cant complete the unmounting */
/* The issue makes the page scrollable and a gap at the bottom of the page */
/* ref: https://github.com/tvkhoa/react-tippy/issues/68 */
.tippy-popper[style*='visibility: hidden'] {
  display: none;
}

.tippy-tooltip.raw-theme {
  padding: 0;
  background: rgb(32, 32, 32);
}

.tippy-popper[x-placement^='top'] .tippy-tooltip.raw-theme [x-arrow] {
  border-top-color: rgb(32, 32, 32);
}
.tippy-popper[x-placement^='bottom'] .tippy-tooltip.raw-theme [x-arrow] {
  border-bottom-color: rgb(32, 32, 32);
}
.tippy-popper[x-placement^='left'] .tippy-tooltip.raw-theme [x-arrow] {
  border-left-color: rgb(32, 32, 32);
}
.tippy-popper[x-placement^='right'] .tippy-tooltip.raw-theme [x-arrow] {
  border-right-color: rgb(32, 32, 32);
}
